<template>
  <div class="direction-info-div">
    <div class="flex-item">
      <div class="dispatch-info">
        <p class="heading-text">Dispatches Info</p>
        <span class="mdi mdi-call-split icon-route"></span>
      </div>
    </div>
    <div class="sub-info">
      <p class="sub-info-text">
        Total Dispatches : {{ getDispatchesInfo.totalDispatches }}
      </p>
      <p class="sub-info-text">
        Showing Routes for Dispatches : {{ getDispatchesInfo.afterFilteration }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("map/routing", ["getDispatchesInfo"]),
  },
};
</script>

<style>
.heading-text {
  font-weight: 700;
  color: aqua;
  font-size: 16px;
  /* heading-text */
}
.direction-info-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 80px;
  right: 10px;
  background-color: #351431;
  padding: 5px;
  top: 40%;
  border-radius: 20px;
}
.dispatch-info {
  display: flex;
  flex-direction: row;
  line-height: 0px;
}
.sub-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-top: 5px;
}
.sub-info-text {
  margin-top: 5px;
  font-weight: 600;
  color: #fff;
}
</style>
