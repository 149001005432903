<template>
  <div class="containerr">
    <div id="map" class="map"></div>
    <div class="floating-control">
      <div style="background-color: #1a936f; padding: 8px">
        <input
          type="button"
          class="btn"
          value="Display Dispatches"
          @click="getDispatchesForDriverr"
        />
      </div>
    </div>
    <dispatch-info v-if="getShowRoutingInfo" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DispatchInfo from "@/components/Map/DispatchInfo";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  name: "MapI",
  components: {
    DispatchInfo,
  },
  data: () => ({
    connection: null,
  }),
  computed: {
    ...mapGetters("map/routing", ["getShowRoutingInfo"]),
  },
  methods: {
    ...mapActions("map", ["drawMapAndIcons", "clearState"]),
    ...mapActions("map/routing", ["getDispatchesForDriver"]),
    getDispatchesForDriverr() {
      console.log("set for v1 ...");
    },
  },

  mounted() {
    this.drawMapAndIcons();
    this.$store.dispatch("page/setPage", "map");
  },

  beforeDestroy() {
    this.clearState();
  },
};
</script>

<style lang="scss">
.map {
  // background-color: rgb(13, 41, 13);
  margin-left: 20px;
  width: 98%;
  height: 90vh;
}

.custom-popup .mapboxgl-popup-content {
  color: #fff;
  background-color: #4a4a4a;
}
.subHeading {
  font-weight: 400;
  font-size: 16px;
}
.headingRowPopup {
  font-weight: 600;
  font-size: 15px;
}
.infoText {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}
.divHeight {
  height: 60px;
}
.subRow {
  margin-top: -10px;
  font-weight: 400;
}
.twoRowHeight {
  height: 100%;
}
.firstRowPopup {
  font-size: 15px;
}
.custom-size {
  margin-top: 2px;
  font-size: 15px;
}
.activeDriverShip {
  margin-top: -10px;
  display: flex;
  justify-content: center;
}
.activeShipment .custom-popup .mapboxgl-popup-content {
  height: 30px;
}
.custom-icon {
  margin-top: -12px;
  display: flex;
}

.custom-control {
  position: absolute;
  top: 10px;
  background-color: black;
  left: 20px;
  color: #fff;
  .second-btn {
    background-color: blue;
    margin-left: 20px;
  }
}
.meter {
  background-color: #fff;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 50%;
}
.floating-control {
  position: absolute;
  top: 10px;
  left: 20px;
}
.btn {
  color: #fff;
  font-weight: 600;
}

.icon-route {
  margin-left: 5px;
  font-size: 18px;
  color: #fff;
}
.flex-item {
  display: flex;
  margin-top: 10px;
}
</style>
